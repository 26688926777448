<template>
	<div class="sort-table-wrap">
		<v-data-table
			ref="sortTable"
			:expanded.sync="expanded"
			:headers="headers"
			:items="items"
			:show-expand="expand"
			:single-expand="false"
			class="sort-table"
			disable-pagination
			disable-sort
			hide-default-footer
			mobile-breakpoint="1"
			@click:row="openDetail"
		>
			<template v-slot:header.checked>
				<label
					class="d-flex align-center justify-center"
					@click="onLabelCheckedAllItems"
				>
					<v-simple-checkbox
						:indeterminate="isIndeterminate"
						:ripple="false"
						:value="isCheckedAllItems"
						@input="onCheckedAllItems"
					></v-simple-checkbox>
				</label>
			</template>
			<template
				v-for="header in checkHeaders"
				v-slot:[addItemText(header.value)]="{ item }"
			>
				<slot :item="item" :name="header.value">
					{{ item[header.value] }}
				</slot>
			</template>
			<template v-slot:item.lastUpdateTime="{ item: { lastUpdateTime } }">
				{{ $formatDate(new Date(lastUpdateTime), 'yyyy-MM-dd HH:mm') }}
			</template>
			<template v-slot:item.sort="{ item }">
				<img
					alt=""
					class="sort-icon"
					src="../assets/common_btn_sort.png"
					@mousedown="onSortingMousedown($event, item)"
					@touchstart="onSortingMousedown($event, item)"
				/>
			</template>
			<template v-slot:item.checked="{ item }">
				<v-simple-checkbox
					v-if="checkboxFilter(item)"
					v-model="item.checked"
					:ripple="false"
				/>
			</template>

			<template v-slot:expanded-item="{ item }">
				<td :colspan="headers.length">
					<slot :item="item" name="expanded-item"></slot>
				</td>
			</template>
		</v-data-table>
		<!--    <v-snackbar-->
		<!--      class="sorting-tip"-->
		<!--      v-model="isSorting"-->
		<!--      :timeout="0"-->
		<!--      top-->
		<!--      color="grey lighten-5"-->
		<!--      v-if="isMobile"-->
		<!--    >-->
		<!--      <v-subheader-->
		<!--        class="sorting-tip__text"-->
		<!--        v-if="curMobileSortList"-->
		<!--      >-->
		<!--        <div class="sorting-tip__text__title">預計交換的項目：</div>-->
		<!--        <div class="sorting-tip__text__name">{{ curMobileSortList.name }}</div>-->
		<!--      </v-subheader>-->
		<!--      <v-btn-->
		<!--        class="sorting-tip__cancel"-->
		<!--        color="grey darken-4"-->
		<!--        text-->
		<!--        @click="onCancelSorting"-->
		<!--      >-->
		<!--        <span class="text">取消</span>-->
		<!--        <span class="esc">(Esc)</span>-->
		<!--      </v-btn>-->
		<!--    </v-snackbar>-->
		<div id="fixed-table" class="v-data-table">
			<table>
				<tbody>
				<tr id="fixed-tr"></tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'SortCheckboxTable',
	// components: { COMPONENT_NAME },
	props: {
		headers: {
			required: true,
			type: Array,
		},
		items: {
			required: true,
			type: Array,
		},
		httpRoute: {
			type: String,
			required: false,
		},
		httpPath: {
			required: false,
			type: String,
		},
		checkboxFilter: {
			type: Function,
			required: false,
			default: () => true,
		},
		categoryId: {
			type: Number,
			required: false,
		},
		classTypeSorted: {
			type: Boolean,
			required: false,
			default: false,
		},
		expand: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			isCheckedAllItems: false,
			isIndeterminate: false,
			isMobile: false,
			// 拖曳用
			/// MOBILE
			isSorting: false,
			curMobileSortList: null,
			/// PC
			originSortListDOM: null,
			originSortingIndex: 0,
			curSortingIndex: 0,
			mousedownMinusX: 0,
			mousedownMinusY: 0,
			expanded: [],
		}
	},
	computed: {
		checkHeaders() {
			return this.headers.filter(v => v.value !== 'data-table-expand')
		},
	},
	watch: {
		items: {
			deep: true,
			handler(newItems) {
				let checkedLen = 0
				newItems.forEach((e, i) => {
					if (e.checked) {
						checkedLen++
					}
				})
				if (newItems.length === 0) {
					// 若無 items 的話不勾選
				} else if (checkedLen === newItems.length) {
					this.isCheckedAllItems = true
					this.isIndeterminate = false
				} else if (checkedLen > 0) {
					this.isCheckedAllItems = false
					this.isIndeterminate = true
				} else {
					this.isCheckedAllItems = false
					this.isIndeterminate = false
				}
			},
		},
	},
	// created() {},
	mounted() {
		const isMobile = this.$checkMobile()
		this.isMobile = isMobile
		if (!isMobile) {
			window.addEventListener('mousemove', this.onSortMousemove)
			window.addEventListener('mouseup', this.onSortMouseup)
		} else {
			window.addEventListener('touchmove', this.onSortMousemove, {
				passive: false,
			})
			window.addEventListener('touchend', this.onSortMouseup, {
				passive: false,
			})
			// window.addEventListener('keydown', this.onKeyESCCancelSorting)
		}
		document.addEventListener('scroll', this.onTableLazy)
	},
	beforeDestroy() {
		if (!this.isMobile) {
			window.removeEventListener('mousemove', this.onSortMousemove)
			window.removeEventListener('mouseup', this.onSortMouseup)
		} else {
			window.removeEventListener('touchmove', this.onSortMousemove, {
				passive: false,
			})
			window.removeEventListener('touchend', this.onSortMouseup, {
				passive: false,
			})
			// window.removeEventListener('keydown', this.onKeyESCCancelSorting)
		}
		document.removeEventListener('scroll', this.onTableLazy)
	},
	methods: {
		checkAllList() {
			this.items.forEach(e => {
				if (this.checkboxFilter(e)) {
					this.$set(e, 'checked', true)
				}
			})
			this.isCheckedAllItems = true
			this.isIndeterminate = false
		},
		cancelAllList() {
			this.items.forEach(e => {
				if (this.checkboxFilter(e)) {
					this.$set(e, 'checked', false)
				}
			})
			this.isCheckedAllItems = false
			this.isIndeterminate = false
		},
		onCheckedAllItems(checked) {
			checked ? this.checkAllList() : this.cancelAllList()
		},
		onLabelCheckedAllItems() {
			this.isCheckedAllItems ? this.cancelAllList() : this.checkAllList()
		},
		onSortingMousedown(e, item) {
			const clientX = e.clientX || e?.changedTouches?.[0]?.clientX
			const clientY = e.clientY || e?.changedTouches?.[0]?.clientY
			const { isMobile, items } = this
			const { id } = item
			let index

			index = items.findIndex(e => e.id === id)

			const fixedTable = document.getElementById('fixed-table')
			const fixedTr = document.getElementById('fixed-tr')
			const tbody = document.querySelector('.sort-table tbody')
			const trs = tbody.querySelectorAll('tr')
			const curTr = trs[index]
			const curTrBound = curTr.getBoundingClientRect()
			const cleft = curTrBound.left + 20
			const ctop = curTrBound.top
			fixedTr.innerHTML = curTr.innerHTML
			curTr.classList.add('cache')
			curTr.style.opacity = 0.6
			curTr.style.backgroundColor = 'rgba(0, 0, 0, 0.1)'
			for (let i = 0; i < curTr.children.length; i++) {
				fixedTr.children[i].style.minWidth = e.clientWidth + 'px'
			}
			fixedTr.children[0].innerHTML = ''
			fixedTr.style.height = curTr.clientHeight + 'px'
			fixedTable.style.boxShadow = '0 10px 20px 1px rgba(0,0,0,.2)'
			fixedTable.style.position = 'fixed'
			fixedTable.style.left = cleft + 'px'
			fixedTable.style.top = ctop + 'px'
			fixedTable.style.background = '#fff'
			fixedTable.style.zIndex = 999
			fixedTable.style.display = 'block'
			this.isSorting = true
			this.originSortListDOM = curTr
			this.originSortingIndex = index
			this.curSortingIndex = index
			this.mousedownMinusX = clientX - cleft
			this.mousedownMinusY = clientY - ctop
		},
		onSortMousemove(e) {
			const winScrollY = window.scrollY || document.body.scrollTop

			const clientX = e.clientX || e?.changedTouches?.[0]?.clientX
			const clientY = e.clientY || e?.changedTouches?.[0]?.clientY
			const pageY =
				e.clientY + winScrollY || e?.changedTouches?.[0]?.clientY + winScrollY
			if (this.isSorting) {
				e.preventDefault()
				const { originSortListDOM, mousedownMinusX, mousedownMinusY } = this

				const fixedTable = document.getElementById('fixed-table')
				const fixedTr = document.getElementById('fixed-tr')
				const sortTable = this.$refs.sortTable
				const stOffT =
					sortTable.$el.offsetTop +
					(document.getElementsByClassName('tab-header')[0]?.clientHeight ||
						0) +
					(document.getElementsByClassName('page-title-wrap')[0]
						?.clientHeight || 0)
				const tbody = document.querySelector('.sort-table tbody')
				const trs = tbody.querySelectorAll('tr:not(.cache)')
				const compX = clientX - mousedownMinusX
				const compY = clientY - mousedownMinusY

				fixedTable.style.left = compX + 'px'
				fixedTable.style.top = compY + 'px'
				for (let tri = 0; tri < trs.length; tri++) {
					const tr = trs[tri]

					const isNext = tri + 1 === trs.length ? false : true
					const nextTri = isNext ? tri + 1 : tri
					const nextTr = trs[nextTri]
					const offT = tr.offsetTop
					const ot = offT + stOffT
					const nextOffT = nextTr.offsetTop
					const nextHeight = nextTr.clientHeight
					const ot2 = isNext
						? nextOffT + stOffT
						: nextOffT + stOffT + nextHeight

					if (pageY > ot && pageY < ot2) {
						this.curSortingIndex = tri + 1

						tbody.insertBefore(originSortListDOM, nextTr)
						break
					} else if (pageY < trs[0].offsetTop + stOffT) {
						this.curSortingIndex = 0
						tbody.insertBefore(originSortListDOM, trs[0])

						break
					} else if (
						pageY >
						stOffT +
						sortTable.$el.querySelector('.v-data-table__wrapper').clientHeight
					) {
						this.curSortingIndex = trs.length
						tbody.appendChild(originSortListDOM)
						break
					}
				}
			}
		},
		changeSortIds(beginIndex, endIndex, afterSortIds) {
			const afterSortIdsLength = afterSortIds?.length || 0
			for (let index = 0; index < afterSortIdsLength; index++) {
				const afterSortId = afterSortIds[index]
				const isMinus = beginIndex > endIndex
				const minusIndex = isMinus ? afterSortIdsLength - index - 1 : -index
				// this.$set(this.items[Math.abs(beginIndex - minusIndex)], 'sortId', afterSortId)
			}
		},
		async onSorted(firstIndex, lastIndex) {
			// START:: 搓排序 api
			const { classTypeSorted, httpRoute, httpPath, items } = this

			if (classTypeSorted) {
				this.$emit('onSorted', {
					firstIndex: firstIndex,
					lastIndex: lastIndex,
				})
			} else {
				try {
					const globalItem = items.splice(firstIndex, 1)[0]
					items.splice(lastIndex, 0, globalItem)
					const sortedIdList = items.map(v => v.id)

					const apiMethod = httpPath ? this.$http[httpRoute][httpPath] : this.$http[httpRoute]
					if (!apiMethod) {
						console.error('API 不存在！')
						return
					}

					await apiMethod.sort({
						...this.$getStoreId(),
						categoryId:
							this.$route.query.categoryId || this.categoryId || undefined,
						sortedIdList: sortedIdList,
					})
					this.changeSortIds(firstIndex, lastIndex, sortedIdList)
					this.$store.commit('showMessage', '排序操作成功')
				} catch (error) {
					$devLog('error', error)
					this.$store.commit('showMessage', {
						text: '排序操作異常',
						color: 'error',
					})
				}
			}

			// END:: 搓排序 api
		},
		onSortMouseup(ev) {
			const { isSorting } = this
			if (isSorting) {
				const { originSortingIndex, curSortingIndex, items } = this
				const fixedTable = document.getElementById('fixed-table')
				const cacheTr = document.querySelector('tr.cache')
				fixedTable.style.display = 'none'
				cacheTr.style.opacity = 1
				cacheTr.style.backgroundColor = '#fff'
				cacheTr.classList.remove('cache')
				this.isSorting = false
				if (originSortingIndex !== curSortingIndex) {
					this.onSorted(originSortingIndex, curSortingIndex)
				}
			}
		},
		onKeyESCCancelSorting({ key }) {
			if (key === 'Escape') {
				this.isSorting = false
			}
		},
		onCancelSorting() {
			this.isSorting = false
		},
		onChangeListIndex(item) {
			const { originSortingIndex, items } = this
			let lastIndex = items.findIndex(e => e.id === item.id)
			const tbody = document.querySelector('.sort-table tbody')
			const trs = tbody.querySelectorAll('tr')
			const originTr = trs[originSortingIndex]
			const changeTr = trs[lastIndex]
			tbody.insertBefore(originTr, changeTr)
			this.onCancelSorting()
			this.onSorted(originSortingIndex, lastIndex)
		},
		addItemText(key) {
			return `item.${key}`
		},
		onTableLazy() {
			const isCall = this.$checkLazy()
			isCall && this.$emit('onTableLazy')
		},
		openDetail(row) {
			if (this.expand) {
				const same = this.expanded?.findIndex(v => v.id === row.id)
				if (same !== -1) {
					this.expanded = this.expanded.filter((item, i) => i !== same)
				} else {
					this.expanded.push(row)
					this.$emit('openDetail', row)
				}
			} else {
				this.$emit('clickRow', row)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.sort-icon {
	cursor: pointer;
	opacity: 0.3;
	height: 24px;
	width: 24px;
	margin: 2px 0 0 0;
}

.sort-ud-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

::v-deep {
	.v-snack__content {
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
	}
}

.sort-table {
	&::v-deep {
		.v-data-table__wrapper {
			table {
				.v-simple-checkbox {
					justify-content: center;
				}

				@include rwd(768) {
					.v-simple-checkbox {
						margin-right: 4px !important;
					}
					th,
					td {
						padding: 3px !important;
					}
				}
			}
		}
	}
}
</style>
