import root from './root'
import category from './category'
import store from './store'
import business from './business'
import contract from './contract'
import menu from './menu'
import member from './member'
import order from './order'
import pos from './pos'
import announcement from './announcement'
import announcementTemplate from './announcementTemplate'
import system from './system'
import mail from './mail'
import cashDrawerRecord from './cashDrawerRecord'
import automaticDiscount from '@/utils/service/automaticDiscount'

export const list = {
	...root,
	category,
	store,
	business,
	contract,
	menu,
	member,
	order,
	pos,
	announcement,
	announcementTemplate,
	system,
	mail,
	cashDrawerRecord,
	automaticDiscount
}

export default Vue => {
	Vue.prototype.$http = list
}
